import { Controller } from "stimulus";

import Plotly from "plotly.js/dist/plotly";
import $ from "jquery";

/**
 * Converts a tag to a plotly graph tag.
 *
 *   content_tag :div, nil, class: "span8", data: {
 *     controller: "plotly",
 *     chart: {
 *       title: "chart name",
 *       data: [{
 *         type: 'line',
 *         mode: 'lines',
 *         name: 'average',
 *         x: [1,2,3,4,5],
 *         y: [9,8,7,6,5]
 *       }]
 *     }
 *   }
 *
 */
export default class extends Controller {
  connect() {
    // jquery data() automatically parses hashes whereas dataset does not.
    const data = $(this.element).data();

    Plotly.newPlot(this.element, data.chart.data, { title: data.chart.title });
  }
}
