import { Controller } from "stimulus";

import $ from "jquery";
import tippy from "tippy.js";

/**
 * Wires up "popover" and "tooltip" functionality.
 *
 * Since we don't want to import a super old version of bootstrap into our cool new Webpack setup, we instead use the
 * libraries on which modern Bootstrap iterations rely for the same (arguably better) functionality. As such, we use
 * `tippy.js`, which is based on the `popper.js` library.
 *
 * Using this controller is as simple as adding it to the element to which we want to add a tooltip and adding a `Title`
 * attribute (à la Bootstrap), or using `data-popover-content`:
 *
 *   <a href="/users" title="Users" data-controller="popover">Users!!</a>
 *
 * Alternatively, we can wrap this controller's containing element around a `trigger` target, if we want to specify a
 * target within this controller's scope.
 *
 *   <div data-controller="popover" data-popover-content="This will be the content of the popover">
 *     Some other stuff...
 *
 *     <span data-target="popover.trigger">This will be the target of the tooltip</span>
 *   </div>
 *
 * We can also provide HTML content for the popover using a `content` target, if we'd like. This will be hidden upon
 * page load:
 *
 *   <div data-controller="popover">
 *     <div data-target="popover.content">
 *       This will be the <strong>contents</strong> of the popover!!!
 *     </div>
 *
 *     <div data-target="popover.trigger">
 *       This is the target of the popover.
 *     </div>
 *   </div>
 *
 * You can specify some parameters using Stimulus data attributes:
 *
 *   <div data-controller="popover" data-placement="left">
 *     <!-- Trigger/Content targets go within... -->
 *   </div>
 *
 */
export default class extends Controller {
  static targets = ["trigger", "content"];

  connect() {
    if (this.hasContentTarget) $(this.contentTarget).hide();

    this.tippyInstance = tippy(this.targetElement, {
      allowHTML: !!this.htmlContent,
      content: this.content,
      placement: this.placement,
      interactive: true,
      theme: this.theme,
    });
  }

  disconnect() {
    this.tippyInstance.destroy();
  }

  get targetElement() {
    return this.hasTriggerTarget ? this.triggerTarget : this.element;
  }

  get content() {
    return (
      this.htmlContent ||
      this.textContent ||
      this.targetElement.getAttribute("title")
    );
  }

  get htmlContent() {
    return this.hasContentTarget ? this.contentTarget.innerHTML : null;
  }

  get textContent() {
    return this.data.has("content") ? this.data.get("content") : "";
  }

  get placement() {
    return this.data.has("placement") ? this.data.get("placement") : "top";
  }

  get theme() {
    return this.data.has("theme") ? this.data.get("theme") : "light";
  }
}
