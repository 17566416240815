import { Controller } from "stimulus";

/*
 * Sometimes we want to arrange a table such that, for each row, there is a supplemental row underneath that is hidden
 * by default. Since the HTML specification does not allow us to wrap `tr`s inside of another element (in which case
 * it would be sufficient to simply use the existing display_toggle_controller), we need to wrap the entire table (or
 * tbody) in this controller and specify the collapsible targets by a unique identifier.
 *
 *   <table data-controller="collapsible-table">
 *     <tr>
 *       <td>
 *         <button data-action="data-collapsible#toggle" data-collapsible-id="1">Collapse</button>
 *       </td>
 *     </tr>
 *     <tr data-target="collapsible" data-collapsible-id="1">
 *       <td>This row will be collapsed until you click the "Collapse" button above me!</td>
 *     </tr>
 *
 *     <tr>
 *       <td>
 *         <button data-action="data-collapsible#toggle" data-collapsible-id="2">Collapse</button>
 *       </td>
 *     </tr>
 *     <tr data-target="collapsible" data-collapsible-id="2">
 *       <td>Same idea as above, but it'll only toggle when you click the "Collapse" button with the specified ID!</td>
 *     </tr>
 *   </table>
 */
export default class extends Controller {
  static targets = ["collapsible"];

  connect() {
    this.collapsibleTargets.forEach(
      (target) => (target.style.display = "none")
    );
  }

  toggle(e) {
    const collapsibleId = e.currentTarget.dataset.collapsibleId;

    this.collapsibleTargets.forEach((target) => {
      if (target.dataset.collapsibleId !== collapsibleId) return;

      target.style.display = target.style.display === "none" ? null : "none";
    });
  }
}
