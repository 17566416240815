import { Controller } from "stimulus";

import $ from "jquery";
const datepicker = require("jquery-ui/ui/widgets/datepicker");

datepicker.setDefaults({ dateFormat: "DD, d MM, yy" });

/**
 * Converts an input tag to a datpicker tag.
 *
 *   <input type="text" data-controller="datepicker">
 *
 */
export default class extends Controller {
  connect() {
    $(this.element).datepicker();
  }
}
