import ContentWithLoadingController from "./content_with_loading_controller";

import axios from "axios";
import queryString from "query-string";
import { merge } from "lodash";

export default class extends ContentWithLoadingController {
  connect() {
    if (this.data.has("preloaded") && this.data.get("preloaded")) {
      this.isLoaded();
    } else {
      this.load();
    }
  }

  load() {
    this.isLoading();

    if (!this.path) return;

    axios
      .get(this.path, { responseType: "text" })
      .then((response) => {
        this.contentWrapperTarget.innerHTML = response.data;
        this.isLoaded();
      })
      .catch(() => this.isErrored());
  }

  reload(e) {
    e.preventDefault();

    this.load();
  }

  loadFromXHR(e) {
    const content = e.detail[0].content;
    this.contentWrapperTarget.innerHTML = content;
    this.isLoaded();
  }

  get path() {
    const params = this.data.has("params") ? this.data.get("params") : null;
    const locals = this.data.has("locals") ? this.data.get("locals") : null;

    if (!this.partialName) return null;

    const basePath = "/partial.html";

    const baseObj = queryString.parseUrl(basePath);
    const paramsObj = queryString.parse(params);
    const localsObj = locals || "{}";

    baseObj.query.partial_name = this.partialName;

    merge(baseObj.query, paramsObj, { locals: localsObj });

    return queryString.stringifyUrl(baseObj);
  }

  get partialName() {
    return this.data.has("name") ? this.data.get("name") : null;
  }
}
