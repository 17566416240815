import { Controller } from "stimulus";

/*
 * A Stimulus implementation of the legacy `link_to_add_fields`. In a Rails form, prefer to use `collection_fields_for`
 * to assist with this controller.
 *
 *   <div data-controller="collection-fields">
 *     <%= f.collection_fields_for :comments do |comment_f| %>
 *       <%= comment_f.text_field :content %>
 *     <% end %>
 *
 *     <%= button_tag '#', data: { action: 'collection-fields#add' } do %>
 *       <%= icon_tag 'plus' %>
 *       Add a comment
 *     <% end %>
 *   </div>
 */
export default class extends Controller {
  static targets = ["blank", "fields", "member"];

  connect() {
    this.blank = this.blankTarget;

    this.blank.parentNode.removeChild(this.blank);
  }

  add(e) {
    e.preventDefault();

    const newID = new Date().getTime();
    const newHTML = this.blank.innerHTML.replace(/CF_NEW_ID/g, newID);
    const newNode = document.createElement("div");

    newNode.innerHTML = newHTML;
    newNode.firstChild.dataset.newRecord = "true";

    this.fieldsTarget.appendChild(newNode.firstChild);
  }

  remove(e) {
    e.preventDefault();

    this.memberTargets.forEach((member) => {
      if (!member.contains(e.target)) return;

      if (member.dataset.newRecord) {
        member.parentNode.removeChild(member);
      } else {
        this.markForDestruction(member);
      }
    });
  }

  markForDestruction(member) {
    const hiddenElement = document.createElement("input");

    hiddenElement.type = "hidden";
    hiddenElement.name = "_destroy";
    hiddenElement.value = "1";

    member.appendChild(hiddenElement);
    member.style.display = "none";
  }
}
