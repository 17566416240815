import { Controller } from "stimulus";

import $ from "jquery";
import {} from "select2/dist/js/select2";
import "select2/dist/css/select2.css";
import { merge } from "lodash";

import configs from "admin/models/select2_configs";

/**
 * 🎶 It appears we can use a fresh version of select2 in webpack without conflicting with the global one 🎶
 *
 * Converts a `select` into a `select2` dropdown.
 *
 *   <select name="color" data-controller="select2">
 *     <option value="red">Red</option>
 *     <option value="green">Green</option>
 *   </select>
 *
 * You can configure your select2 tag to use an AJAX endpoint or behave a certain way by using the `data-select2-config`
 * attribute. This corresponds to one of the configs in `client/admin/models/select2_configs/`. To create a config,
 * add a file in that folder that exports a config object (see https://select2.org/configuration/options-api), then
 * import/add that to the object exported in `client/admin/models/select2_configs.js`.
 *
 *   <select name="user_id" data-controller="select2" data-select2-config="employee_search">
 *   </select>
 *
 * Note that, with our older version of select2, AJAX select tags required that you use a `input type="hidden"` field,
 * but now that is no longer required.
 *
 * ## Prepopulating values
 *
 * If we're using an AJAX function to populate the available values, we will need to provide a way to populate existing
 * values. While this could sometimes be accomplished by simply rendering the view with `<option selected>` tags from
 * the back-end, this might not always be the best option to ensure that the pre-rendered options are
 * formatted consistently with what the AJAX call gives us. As such, you can optionally provide a `data-select2-value`
 * attribute with the existing value (values should be comma-separated if it is a mult-select).
 *
 *   <select
 *     name="supplier_id"
 *
 *     data-controller="select2"
 *     data-select2-config="supplierSearch"
 *     data-select2-value="1443"
 *   ></select>
 *
 * The associated config should add a `ajax._populateSelected` key that points to an **async** function that takes the
 * value and whose promise returns an `<option>` tag. Note that this does not necessarily need to actually make an AJAX
 * call, but it should always be an async function.
 *
 *   // Some config
 *   export default {
 *     // ...
 *
 *     ajax: {
 *       // ...
 *
 *       async _populateSelected(value) {
 *         const response = await axios.get(`/things/${value}.json`)
 *
 *         return new Option(response.data.name, response.data.id, true, true)
 *       }
 *     }
 *   }
 *
 * If `data-select2-value` is present and the config has an `ajax._populateSelected` function, then the value for this
 * select2 dropdown will be populated upon load. The reasoning behind this is that the formatting logic of the selection
 * dropdown can live in the same place as that of the pre-initialized selected value.
 *
 * See also: https://select2.org/programmatic-control/add-select-clear-items#preselecting-options-in-an-remotely-sourced-ajax-select2
 *
 */
export default class extends Controller {
  connect() {
    const processedConfig = merge(this.config, this.configOverrides);

    $(this.element).select2(processedConfig);

    this.populateSelected().then(() => this.postprocess());
  }

  disconnect() {
    $(this.element).select2("destroy");
  }

  get config() {
    return this.data.has("config")
      ? this.configs[this.data.get("config")]
      : this.configs.default;
  }

  get configs() {
    return configs;
  }

  get readonly() {
    return !!this.element.getAttribute("readonly");
  }

  get configOverrides() {
    const result = {};

    if (this.readonly) result.disabled = true;

    return result;
  }

  async populateSelected() {
    if (!this.data.has("value") || !this.data.get("value")) return;
    if (!(this.config.ajax && this.config.ajax._populateSelected)) return;

    const rawValue = this.data.get("value");
    const values = rawValue.split(",");

    const promises = values.map((value) => this.populateValue(value));

    return Promise.all(promises);
  }

  async populateValue(value) {
    const option = await this.config.ajax._populateSelected(value);

    // We use jQuery to trigger events here, since Select2 uses jQuery
    // events rather than native JavaScript events.
    $(this.element).append(option).trigger("change.select2");

    $(this.element).trigger("select2:select", {
      params: { id: option.value, next: option.innerText },
    });
  }

  postprocess() {
    if (this.readonly) this.processReadonly();
  }

  // Later versions of Select2 no longer support the `readonly` flag, so we
  // need to create a hidden element containing the value so HTML forms will
  // still submit the value.
  processReadonly() {
    const hiddenElement = document.createElement("input");

    hiddenElement.setAttribute("type", "hidden");
    hiddenElement.setAttribute("name", this.element.name);
    hiddenElement.setAttribute("value", this.element.value);

    this.element.parentNode.insertBefore(hiddenElement, this.element);
    this.element.removeAttribute("readonly");
  }
}
