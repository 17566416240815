import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["priceOnline", "shippingOnline", "output"];

  refresh() {
    this.outputTargets.forEach((element) => {
      const discount = parseFloat(element.dataset.discount);

      element.value = this.calculateWholesale(discount);
    });
  }

  calculateWholesale(discount) {
    const ourTotal =
      (this.priceOnline + this.shippingOnline) * (1.0 - discount);
    let ourPrice;
    let ourCost;

    if (ourTotal > 1070) {
      ourPrice = ourTotal / 1.07;
    } else if (ourTotal >= 960) {
      ourPrice = ourTotal - 60.0;
    } else if (ourTotal >= 800) {
      ourPrice = ourTotal - 50.0;
    } else if (ourTotal >= 540) {
      ourPrice = ourTotal - 40.0;
    } else if (ourTotal >= 330) {
      ourPrice = ourTotal - 30.0;
    } else if (ourTotal >= 225) {
      ourPrice = ourTotal - 25.0;
    } else if (ourTotal >= 115) {
      ourPrice = ourTotal - 15.0;
    } else if (ourTotal >= 60) {
      ourPrice = ourTotal - 10.0;
    } else if (ourTotal >= 33) {
      ourPrice = ourTotal - 8.0;
    } else if (ourTotal >= 5) {
      ourPrice = ourTotal - 5.0;
    } else {
      ourPrice = 0;
    }

    ourCost = (ourPrice * 0.55).toFixed(2);
    return ourCost;
  }

  get priceOnline() {
    return parseFloat(this.priceOnlineTarget.value);
  }

  get shippingOnline() {
    return parseFloat(this.shippingOnlineTarget.value);
  }
}
