import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "row",
    "quantityTotal",
    "soldTotal",
    "remainingTotal",
    "returnTotal",
  ];

  initialize() {
    this.addTotals();
  }

  computeRemaining(e) {
    e.preventDefault();

    this.rowTargets.forEach((row) => {
      const remainingEle = row.querySelector('[data-value="remaining"]');
      const resultEle = row.querySelector('[data-value="result"]');

      const remaining = parseInt(remainingEle.innerText);

      resultEle.value = remaining;
    });
    this.computeReturnTotal(e);
  }

  computeReturnTotal(e) {
    e.preventDefault();

    let total = 0;

    this.rowTargets.forEach((row) => {
      const resultEle = row.querySelector('[data-value="result"]');

      total += parseInt(resultEle.value) || 0;
    });

    this.returnTotalTarget.innerText = total;
  }

  addTotals() {
    let quantityTotal = 0;
    let soldTotal = 0;
    let remainingTotal = 0;

    this.rowTargets.forEach((row) => {
      const quantityValue = row.querySelector('[data-value="quantity"]');
      const soldValue = row.querySelector('[data-value="sold"]');
      const remainingValue = row.querySelector('[data-value="remaining"]');

      quantityTotal += parseInt(quantityValue.innerText);
      soldTotal += parseInt(soldValue.innerText);
      remainingTotal += parseInt(remainingValue.innerText);
    });

    this.quantityTotalTarget.innerText = quantityTotal;
    this.soldTotalTarget.innerText = soldTotal;
    this.remainingTotalTarget.innerText = remainingTotal;
  }
}
