import { Controller } from "stimulus";

const flatpickr = require("flatpickr");
require("flatpickr/dist/flatpickr.css");

/**
 * Converts an input tag to a datetimepicker tag.
 *
 *   <input type="text" data-controller="datetimepicker">
 *
 */
export default class extends Controller {
  connect() {
    this.fp = flatpickr(this.element, {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
    });
  }

  disconnect() {
    if (this.fp) this.fp.destroy();
  }
}
