export default {
  tags: true,

  placeholder: "Enter some tags...",
  minimumInputLength: 0,

  ajax: {
    dataType: "json",

    data: function ({ term }) {
      return {
        q: term,
        per_page: 30,
      };
    },

    processResults: function (data, page) {
      return {
        results: data.tags.map(function (tag) {
          return {
            id: tag.name,
            text: tag.name,
          };
        }),
      };
    },
  },
};
