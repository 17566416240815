import { Controller } from "stimulus";

import $ from "jquery";

/**
 * Hook this up to a checkbox to toggle selected targets.
 *
 *   <div data-controller="display-toggle">
 *     <input type="checkbox" data-target="display-toggle.state" data-action="change->display-toggle#update">
 *
 *     <ul>
 *       <li data-target="display-toggle.state">This will be hidden unless the above checkbox is checked</li>
 *       <li>This will always show</li>
 *       <li data-target="display-toggle.state">This will also be hidden unless the above checkbox is checked</li>
 *     </ul>
 *   </div>
 *
 * You can also keep an internal state in lieu of a `state` target (we use the `toggle` action in this case):
 *
 *   <div data-controller="display-toggle">
 *     <a href="#" data-action="display-toggle#toggle">Toggle it!</a>
 *
 *     <div data-target="display-toggle.display">
 *       This will turn on or off when we click the link above!
 *     </div>
 *   </div>
 */
export default class extends Controller {
  static targets = ["state", "display", "inverse"];

  connect() {
    this.update();
  }

  update() {
    if (this.state) {
      this.displayTargets.forEach((element) => $(element).show());
      this.inverseTargets.forEach((element) => $(element).hide());
    } else {
      this.displayTargets.forEach((element) => $(element).hide());
      this.inverseTargets.forEach((element) => $(element).show());
    }
  }

  toggle(e) {
    e.preventDefault();

    this.internalState = !this.internalState;

    this.update();
  }

  show(e) {
    e.preventDefault();

    this.internalState = true;

    this.update();
  }

  hide(e) {
    e.preventDefault();

    this.internalState = false;

    this.update();
  }

  // Returns a boolean. If true, `displayTargets` should be _shown_; if false, `displayTargets` should be
  // _hidden_.
  get state() {
    return this.hasStateTarget ? this.stateTarget.checked : this.internalState;
  }

  get internalState() {
    return this.data.get("state") === "true";
  }

  set internalState(value) {
    const bool = new Boolean(value);

    this.data.set("state", bool.toString());
  }
}
