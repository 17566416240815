import { Controller } from "stimulus";

/**
 * If you want a remote button's activation to disable multiple buttons, use this controller. If you just want to
 * disable a single button, then it's easier to just use `data-disable-with`.
 *
 *   <div data-controller="ajax-disable">
 *     <a
 *       href="/posts/22" data-remote="true" data-method="patch"
 *       data-target="ajax-disable.disableable"
 *       data-action="ajax:send->ajax-disable#disable ajax:complete->ajax-disable#enable"
 *     >
 *       Update!!!
 *     </a>
 *
 *     <a
 *       href="/posts/22" data-remote="true" data-method="patch"
 *       data-target="ajax-disable.disableable"
 *       data-action="ajax:send->ajax-disable#disable ajax:complete->ajax-disable#enable"
 *     >
 *       Delete!!!
 *     </a>
 *   </div>
 */
export default class extends Controller {
  static targets = ["disableable"];

  disable() {
    this.disableableTargets.forEach((element) =>
      element.setAttribute("disabled", "")
    );
  }

  enable() {
    this.disableableTargets.forEach((element) =>
      element.removeAttribute("disabled")
    );
  }
}
