import { Controller } from "stimulus";

import { every } from "lodash";

/*
 * A "composite" controller that takes a selection-list and uses it to perform bulk actions on elements using
 * display-toggle within. With this controller, we can add a "hide-/show-selected" button and other functionalities
 * that can trigger display-toggle actions on only the selected elements. This requires a few things:
 *
 * 1. This controller must be connected to the same element as the associated selection-list controller.
 * 2. Each selection-list.item target must also be connected to a display-toggle controller.
 *
 *   <div data-controller="selection-list display-toggle-list">
 *     <a href="#" data-action="display-toggle-list#toggle">Toggle selected</a>
 *
 *     <div data-target="selection-list.list">
 *       <div data-controller="display-toggle" data-target="selection-list.item">
 *         <input type="checkbox" data-target="selection-list.itemSelection">
 *
 *         <div data-target="display-toggle.display">This will be hidden until you toggle me!</div>
 *       </div>
 *
 *       <!-- etc -->
 *     </div>
 *   </div>
 */
export default class extends Controller {
  toggle(e) {
    e.preventDefault();

    if (this.aggregateState) {
      this.hide(e);
    } else {
      this.show(e);
    }
  }

  show(e) {
    e.preventDefault();

    this.selectedControllers.forEach((controller) => controller.show(e));
  }

  hide(e) {
    e.preventDefault();

    this.selectedControllers.forEach((controller) => controller.hide(e));
  }

  get selectionListController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      "selection-list"
    );
  }

  get selectedItems() {
    return this.selectionListController.selectedItems;
  }

  get selectedControllers() {
    return this.selectedItems.map((element) => {
      return this.application.getControllerForElementAndIdentifier(
        element,
        "display-toggle"
      );
    });
  }

  get aggregateState() {
    return every(this.selectedControllers, (controller) => controller.state);
  }
}
