import { Controller } from "stimulus";

/*
 * Takes the value of `source` and applies it to a data attribute on `destination`.
 *
 *   <div data-controller="value-transfer">
 *     <input
 *       type="text"
 *       data-target="value-transfer.source"
 *       data-action="change->value-transfer#apply"
 *       data-value-transfer-name="title"
 *     >
 *
 *     <a
 *       href=""
 *       data-target="value-transfer.destination"
 *       data-value-transfer-name="title"
 *      >
 *        This text will become the source's value.
 *      </a>
 *   </div>
 *
 * Destinations can decide how the transferred value wants to populate, as deonted by that targets
 * `data-value-transfer-type`. By default, it will set the value as its `innerHTML`. If the destination is an input or
 * other form control, `data-value-transfer-type="value"` will set the control's value property. You can also set any HTML
 * attribute by setting `data-value-transfer-type="attribute"`. If you do this, you will need to specify the name of the
 * attribute with `data-value-transfer-attribute-name="the-name-of-the-attribute"`.
 *
 * If you need to transfer something other than the source's value, then you can specify any other attribute with
 * `data-value-transfer-source-attribute="the-name-of-the-attribute-on-the-source-target"`.
 *
 * This controller is designed to support multiple sources within its scope in order to make it easier to handle more
 * complicated form interactions.
 */
export default class extends Controller {
  static targets = ["source", "destination"];

  connect() {
    this.applySources();
  }

  applySources() {
    this.sourceTargets.forEach((target) => this.apply({ target }));
  }

  apply({ target }) {
    const valueName = target.dataset.valueTransferName;

    // for `select` tag, we're actually interested in the selection `option`, not the `select` itself
    let trueSource;
    switch (target.tagName) {
      case "SELECT":
        trueSource = target.options[target.selectedIndex];
        break;
      default:
        trueSource = target;
    }

    // allow source to use custom attribute instead of just `value`
    let newValue;
    if (target.dataset.valueTransferSourceAttribute != undefined) {
      newValue = trueSource.getAttribute(
        target.dataset.valueTransferSourceAttribute
      );
    } else {
      newValue = trueSource.value;
    }

    this.destinationTargets.forEach((destination) => {
      if (destination.dataset.valueTransferName !== valueName) return;

      switch (destination.dataset.valueTransferType) {
        case "attribute":
          const attributeName = destination.dataset.valueTransferAttributeName;
          destination.setAttribute(attributeName, newValue);
          break;
        case "value":
          destination.value = newValue;
          break;
        case "inner":
          destination.innerHTML = newValue;
          break;
        default:
          destination.innerHTML = newValue;
      }
    });
  }
}
