import axios from "axios";

export default {
  placeholder: "Search for a revenue opportunity...",

  ajax: {
    url: "/revenue_opportunities/search.json",
    dataType: "json",

    data: function ({ term }) {
      const params = {
        term: term || "",
        per_page: 50,
      };

      if (this[0].dataset.supplierId)
        params["supplier_id"] = this[0].dataset.supplierId;

      return params;
    },

    processResults: function (data, page) {
      return {
        results: data.map(function (item) {
          return {
            id: item.id,
            text: item.name,
          };
        }),
      };
    },

    _populateSelected: async (value) => {
      const response = await axios.get(`/revenue_opportunities/${value}.json`);

      return new Option(
        response.data.revenue_opportunity.name,
        response.data.revenue_opportunity.id,
        true,
        true
      );
    },
  },
};
