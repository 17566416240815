import axios from "axios";

export default {
  placeholder: "Search for a purchase order...",

  ajax: {
    url: "/purchase_orders.json",
    dataType: "json",

    data: function ({ term }) {
      const params = {
        filters: { number: { equal_to: term } },
        per_page: 50,
      };

      if (this[0].dataset.supplierId)
        params["supplier_id"] = this[0].dataset.supplierId;

      return params;
    },

    processResults: function (data, page) {
      return {
        results: data.purchase_orders.map(function (item) {
          const createdDate = new Date(item.created_at);

          return {
            id: item.id,
            text: `${item.number} · ${createdDate.toLocaleDateString()} · ${
              item.amount
            }`,
          };
        }),
      };
    },

    _populateSelected: async (value) => {
      const response = await axios.get(`/purchase_orders/${value}.json`);

      return new Option(
        response.data.purchase_order.number,
        response.data.purchase_order.id,
        true,
        true
      );
    },
  },
};
