import axios from "axios";

export default {
  placeholder: "Search for a supplier selection...",
  minimumInputLength: 0,

  ajax: {
    url: "/supplier_selections/search.json",
    dataType: "json",

    data: function ({ term }) {
      return {
        q: term,
        per_page: 30,
      };
    },

    processResults: function (data, page) {
      return {
        results: data.supplier_selections.map(function (item) {
          return {
            id: item.id,
            text: item.name,
          };
        }),
      };
    },

    _populateSelected: async (value) => {
      const response = await axios.get(`/supplier_selections/${value}.json`);

      return new Option(
        response.data.supplier_selection.name,
        response.data.supplier_selection.id,
        true,
        true
      );
    },
  },
};
