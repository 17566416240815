import { Controller } from "stimulus";

/*
 * Allows us to define arbitrary properties on an element for use by other controllers.
 *
 *   <div data-controller="prop" data-prop-color="green"></div>
 */
export default class extends Controller {
  value(property) {
    return this.data.has(property) ? this.data.get(property) : undefined;
  }
}
