import { Controller } from "stimulus";

import { every, some } from "lodash";

// Represents a list of items that are selectable via checkboxes. Provides functionality for multi-select via
// shift-click (requires a click handler), as well as a "select-all" checkbox.
//
//   <div data-controller="selection-list">
//     <input
//      type="checkbox"
//      data-target="selection-list.listSelection"
//      data-action="change->selection-list#listSelectionChanged"
//     >
//
//     <div data-target="selection-list.list">
//       <div data-target="selection-list.item">
//         <input
//           type="checkbox"
//           data-target="selection-list.itemSelection"
//           data-action="
//            change->selection-list#itemSelectionChanged
//            click->selection-list#itemSelectionClicked
//           "
//         >
//
//         This is the first list item
//       </div>
//
//       <div data-target="selection-list.item">
//         <input
//           type="checkbox"
//           data-target="selection-list.itemSelection"
//           data-action="
//            change->selection-list#itemSelectionChanged
//            click->selection-list#itemSelectionClicked
//           "
//         >
//
//         This is the second list item
//       </div>
//
//       <div data-target="selection-list.item">
//         <input
//           type="checkbox"
//           data-target="selection-list.itemSelection"
//           data-action="
//            change->selection-list#itemSelectionChanged
//            click->selection-list#itemSelectionClicked
//           "
//         >
//
//         This is the third list item
//       </div>
//     </div>
//   </div>
export default class extends Controller {
  static targets = ["list", "item", "listSelection", "itemSelection"];

  itemSelectionChanged(e) {
    this.refreshListSelection();
    this.mostRecentSelection = e.target;
  }

  itemSelectionClicked(e) {
    if (e.shiftKey && e.target.checked) this.selectTo(e.target);
    document.getSelection().removeAllRanges();
  }

  listSelectionChanged() {
    this.itemSelectionTargets.forEach(
      (target) => (target.checked = this.listSelectionTarget.checked)
    );
  }

  refreshListSelection() {
    if (this.allSelected) {
      this.listSelectionTarget.indeterminate = false;
      this.listSelectionTarget.checked = true;
    } else if (this.someSelected) {
      this.listSelectionTarget.checked = false;
      this.listSelectionTarget.indeterminate = true;
    } else {
      this.listSelectionTarget.indeterminate = false;
      this.listSelectionTarget.checked = false;
    }
  }

  selectTo(target) {
    if (!this.mostRecentSelection) return;

    let currentIndex = this.itemSelectionTargets.indexOf(target);
    const targetIndex = this.itemSelectionTargets.indexOf(
      this.mostRecentSelection
    );
    const difference = targetIndex - currentIndex;

    const step = difference < 1 ? -1 : 1;

    while (currentIndex !== targetIndex) {
      currentIndex += step;
      this.itemSelectionTargets[currentIndex].checked = true;
    }
  }

  itemForSelection(selection) {
    return this.itemTargets.find((target) => target.contains(selection));
  }

  get selectedItems() {
    return this.selectedItemSelections.map((selection) =>
      this.itemForSelection(selection)
    );
  }

  get selectedItemSelections() {
    return this.itemSelectionTargets.filter((target) => target.checked);
  }

  get allSelected() {
    return every(this.itemSelectionTargets, (target) => target.checked);
  }

  get someSelected() {
    return some(this.itemSelectionTargets, (target) => target.checked);
  }
}
