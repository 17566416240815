import { Controller } from "stimulus";

/*
 * Manages a collection of elements controlled by `remote_partial_controller`. Allows us to reload specific partials
 * under certain circumstances, enabling some remote partials to "react" to changes reflected in other remote partials.
 *
 *   <div
 *     data-controller="remote-partial-collection"
 *     data-action="ajax:success->remote-partial-collection#reload"
 *     data-reload-partial="things/totals"
 *    >
 *     <%= remote_partial_tag 'things/totals', locals: { things: @things }, collection: true %>
 *
 *     <% @things.each do |thing %>
 *       <%= remote_partial_tag 'things/thing', locals: { thing: thing } %>
 *     <% end %>
 *   </div>
 *
 * The above example will reload the `things/totals` when a Rails-UJS AJAX response comes back down. This will trigger
 * when a remote form is submitted by any children of the event handler, including `things/thing` partials that will
 * be rendered. This effectively allows us to update the totals whenever any one of the `things` changes.
 *
 * This controller is possibly made obsolete by Stimulus Reflex or other technologies, so we should re-evaluate this
 * once we get up to Rails 5.
 */
export default class extends Controller {
  static targets = ["item"];

  reload(e) {
    const rawPartialNames = e.currentTarget.dataset.reloadPartial || "";
    const partialNames = rawPartialNames.split(" ");

    this.itemTargets.forEach((target) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        target,
        "remote-partial"
      );
      if (!controller) return;

      const partialName = controller.partialName;

      if (partialNames.includes(partialName)) controller.reload(e);
    });
  }
}
