import ContentWithLoadingController from "./content_with_loading_controller";

import axios from "axios";
import queryString from "query-string";
import { merge } from "lodash";

/**
 * Load a fragment asynchronously.
 *
 *   <div data-controller="remote-view" data-remote-view-path="/posts">
 *     <div data-target="remote-view.loading">
 *       <img src="/loader.gif">
 *     </div>
 *
 *     <div data-target="remote-view.loadError">:(</div>
 *
 *     <div data-target="remote-view.contentWrapper"></div>
 *   </div>
 *
 * You can trigger a reload of this view using the `load` action:
 *
 *   <button data-action="remote-view#reload">Try again!</button>
 *
 * You can pass query parameters into the reload call with `data-params`:
 *
 *   <button data-action="remote-view#reload" data-params="size=m">Medium</button>
 *   <button data-action="remote-view#reload" data-params="size=l">Large</button>
 *
 * That works for single elements, but for this like `select`, you need to use `data-params-from`, and give it a CSS
 * selector that corresponds to which child element it should get the params from:
 *
 *   <select data-action="change->remote-view#reload" data-params-from=":selected">
 *     <options data-params="size=m" selected>Medium</options>
 *     <options data-params="size=l">Large</options>
 *   </select>
 *
 */
export default class extends ContentWithLoadingController {
  connect() {
    this.load();
  }

  load() {
    this.isLoading();

    if (!this.path) return;

    axios
      .get(this.path, { responseType: "text" })
      .then((response) => {
        this.contentWrapperTarget.innerHTML = response.data;
        this.isLoaded();
      })
      .catch(() => this.isErrored());
  }

  reload(e) {
    e.preventDefault();

    const params = this.extractParamsFromTarget(e.currentTarget);
    this.data.set("params", params);

    this.load();
  }

  extractParamsFromTarget(target) {
    const paramsFrom = target.dataset.paramsFrom
      ? target.querySelector(target.dataset.paramsFrom)
      : target;

    return paramsFrom.dataset.params;
  }

  get path() {
    const basePath = this.data.has("path") ? this.data.get("path") : null;
    const params = this.data.has("params") ? this.data.get("params") : null;

    if (!basePath) return null;

    const baseObj = queryString.parseUrl(basePath);
    const paramsObj = queryString.parse(params);

    baseObj.url += ".html";
    merge(baseObj.query, paramsObj);

    return queryString.stringifyUrl(baseObj);
  }
}
