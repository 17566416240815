import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "itemSelection"];

  select(e) {
    e.preventDefault();

    this.applySelection(e.target.dataset.selectionGroup);
  }

  applySelection(selectionGroup) {
    const tuple = this.tupleForSelectionGroup(selectionGroup);

    this.itemTargets.forEach((target) => {
      const itemSelection = this.itemSelectionForItem(target);
      const propController = this.application.getControllerForElementAndIdentifier(
        target,
        "prop"
      );
      const value = propController.value(tuple[0]);

      if (itemSelection && value === tuple[1])
        this.selectItemSelection(itemSelection);
    });
  }

  itemSelectionForItem(item) {
    return this.itemSelectionTargets.find((target) => item.contains(target));
  }

  tupleForSelectionGroup(selectionGroup) {
    return selectionGroup.split(":");
  }

  selectItemSelection(itemSelection) {
    itemSelection.checked = true;

    itemSelection.dispatchEvent(new Event("click"));
    itemSelection.dispatchEvent(new Event("change"));
  }
}
