import { Controller } from "stimulus";

import FroalaEditor from "froala-editor";
import "froala-editor/css/froala_editor";
import "froala-editor/css/froala_style";

export default class extends Controller {
  connect() {
    this.editor = new FroalaEditor(this.element, {
      key:
        "8JF3bG3A3A2E2A2D1yQNDMIJd1IQNSEa2EUAf1XVFQa1EaD4D3B2B2A18A15A6C9D4A2==",

      // TEMPORARY
      attribution: false,
      charCounterCount: true,
      dragInline: false,
      fontSizeSelection: true,
      spellcheck: true,
      heightMin: 300,
      // END TEMPORARY
    });
  }

  disconnect() {
    this.editor.destroy();
  }
}
