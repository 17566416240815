import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["list"];

  // Assumes an `ajax:success` or `ajax:error` event.
  show(e) {
    const response = e.detail[0];
    if (!response.flash) return;

    for (const level in response.flash) {
      const element = document.createElement("div");

      element.classList.add("alert", `alert-${level}`);
      element.innerText = response.flash[level];

      this.listTarget.appendChild(element);
      setTimeout(() => this.listTarget.removeChild(element), 10 * 1000);
    }
  }
}
