import { Controller } from "stimulus";

/*
 * A controller for tabbed content controlled by radio buttons (potentially expandable beyond radio buttons, too). This
 * is probably not necessary on `admin/application2` pages since Bootstrap 4 introduces more flexible tabbed interface
 * which are preferable to use. This is here just in case you need it (like I did!).
 *
 * To use:
 *
 * 1. Set your radio buttons to `tab` targets (`data-target="tabbable.tab"`)
 * 2. Create a set of `content` targets corresponding with each `tab` target.
 * 3. On each `tab` and `content` target, add a `data-tabbable-name` attribute. There should be a 1:1 correspondence
 *    for values on this attribute between `tab` and `content` target. In other words, both a `tab` target and a
 *    `content` target should have an attribute (e.g.,) `data-tabbable-name="red"`.
 * 4. Add a `click` or `change` action to call `refresh` on each `tab` target: `data-action="click->tabbable#refresh"`
 */
export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    this.refresh();
  }

  refresh() {
    this.displayTabContent(this.currentTabName);
  }

  displayTabContent(tabName) {
    const activeTarget = this.contentTargets.find(
      (target) => target.dataset.tabbableName === tabName
    );
    const inactiveTargets = this.contentTargets.filter(
      (target) => target.dataset.tabbableName !== tabName
    );

    activeTarget.classList.remove("d-none");
    inactiveTargets.forEach((target) => target.classList.add("d-none"));
  }

  get currentTabName() {
    return this.currentTab ? this.currentTab.dataset.tabbableName : "";
  }

  get currentTab() {
    return this.tabTargets.find((target) => target.checked);
  }
}
