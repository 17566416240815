// Initialization for Rails libraries
import Rails from "rails-ujs";
Rails.start();
window.Rails = Rails;

// jQuery event delegation
import delegateJQueryEvent from "../lib/jquery_event_delegator";
delegateJQueryEvent("change.select2", { parameters: ["event"] });
delegateJQueryEvent("change", { parameters: ["event"] });

// Axios defaults
import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Pusher defaults
import Pusher from "pusher-js/with-encryption";
Pusher.logToConsole = process.env.NODE_ENV === "development";
Pusher._tomoAppKey = ["production", "staging"].includes(process.env.NODE_ENV)
  ? "338d93e048e0b9033280"
  : "188b3fc689b0083a667d";
