import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["revenueOpportunity"];

  revenueOpportunityAssigned(e) {
    const coordinatorId = e.currentTarget.dataset.coordinatorId;
    const revenueOpportunityId = e.currentTarget.dataset.revenueOpportunityId;

    this.incrementCoordinatorCounters(coordinatorId);
    this.removeRevenueOpportunityRows(revenueOpportunityId);
  }

  incrementCoordinatorCounters(coordinatorId) {
    document
      .querySelectorAll(`.coord-${coordinatorId} .assignment-count`)
      .forEach((element) => {
        const count = parseInt(element.innerText);

        element.innerText = isNaN(count) ? 1 : count + 1;
      });
  }

  removeRevenueOpportunityRows(revenueOpportunityId) {
    this.revenueOpportunityRows(revenueOpportunityId).forEach((element) =>
      element.parentNode.removeChild(element)
    );
  }

  revenueOpportunityRows(revenueOpportunityId) {
    return this.revenueOpportunityTargets.filter((element) => {
      return element.dataset.revenueOpportunityId === revenueOpportunityId;
    });
  }
}
