import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dependee", "dependent"];

  connect() {
    this.refresh();
  }

  refresh() {
    this.dependeeTargets.forEach((target) =>
      this.refreshScopeForDependee(target)
    );
  }

  refreshScopeForDependee(dependee) {
    const scopeName = dependee.dataset.scope;

    if (dependee.value) {
      this.enableDependentTargets(scopeName);
    } else {
      this.disableDependentTargets(scopeName);
    }
  }

  enableDependentTargets(scopeName) {
    this.dependentTargets.forEach((target) => {
      if (target.dataset.scope !== scopeName) return;

      target.disabled = false;
    });
  }

  disableDependentTargets(scopeName) {
    this.dependentTargets.forEach((target) => {
      if (target.dataset.scope !== scopeName) return;

      target.disabled = true;
    });
  }
}
