import $ from "jquery";

/*
 * Forwards jQuery events as native JavaScript custom events. Since jQuery events are on plane of existence that is
 * separate from JavaScript events, Stimulus controllers cannot respond to jQuery events. You will need to add events
 * to a whitelist in the file `client/admin/index.js` in order to register them.
 *
 *   delegateJQueryEvent('somethingSpecial', { parameters: ['event'] })
 *
 * Please note that, in order to avoid issues like stack overflows and namespace pollution, we prepend all forwarded
 * events with `jquery:`. Thus you will need to write your event listener like so:
 *
 *   <div data-action="jquery:somethingSpecial->thing#doStuff">...</div>
 *
 */
export default (eventName, { parameters }) => {
  const handler = (...args) => {
    const data = {};

    for (let index = 0; index < parameters.length; index++) {
      const name = parameters[index];
      data[name] = args[index];
    }

    const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
      bubbles: true,
      cancelable: true,
      detail: data,
    });

    return data.event.target.dispatchEvent(delegatedEvent);
  };

  return $(document).on(eventName, handler);
};
