import axios from "axios";

export default {
  placeholder: "Search for a supplier...",
  minimumInputLength: 1,
  ajax: {
    url: "/suppliers/search.json",
    dataType: "json",

    data: function ({ term }) {
      return {
        term: term,
        page_limit: 10,
      };
    },

    processResults: function (data, page) {
      return {
        results: data.map(function (item) {
          return {
            id: item.id,
            text: item.name,
          };
        }),
      };
    },

    _populateSelected: async (value) => {
      const response = await axios.get(`/suppliers/${value}.json`);

      return new Option(
        response.data.supplier.name,
        response.data.supplier.id,
        true,
        true
      );
    },
  },
};
