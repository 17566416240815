import axios from "axios";

export default {
  placeholder: "Search for email...",
  minimumInputLength: 1,
  ajax: {
    url: "/users/search.json?role=employee",
    dataType: "json",

    data: function ({ term }) {
      return {
        q: term,
        page_limit: 10,
      };
    },

    processResults: function (data, page) {
      return {
        results: data.map(function (item) {
          return {
            id: item.id,
            text: item.name,
          };
        }),
      };
    },

    _populateSelected: async (value) => {
      const response = await axios.get(`/users/${value}.json?zendesk=false`);

      return new Option(
        response.data.user.email,
        response.data.user.id,
        true,
        true
      );
    },
  },
};
