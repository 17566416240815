import ContentWithLoadingController from "./content_with_loading_controller";

import Pusher from "pusher-js/with-encryption";
import axios from "axios";

const CHANNEL_NAME = "async_action";
const BASE_RENDER_PATH = "/async_actions";

/**
 * Use in conjunction with `AsyncActionable` to watch for completed/errored executions of asynchronous actions.
 */
export default class extends ContentWithLoadingController {
  initialize() {
    this.pusher = new Pusher(Pusher._tomoAppKey, { encrypted: true });
  }

  connect() {
    this.subscribe();
  }

  disconnect() {
    this.unsubscribe();
  }

  subscribe() {
    this.isLoading();

    this.channel = this.pusher.subscribe(this.channelName);

    this.channel.bind("pusher:subscription_succeeded", () =>
      this.clientReady()
    );
    this.channel.bind(`${this.jid}:success`, (...args) =>
      this.renderSuccess(...args)
    );
    this.channel.bind(`${this.jid}:error`, (...args) =>
      this.renderError(...args)
    );
  }

  unsubscribe() {
    this.channel.unbind();
    this.pusher.unsubscribe(this.channelName);
  }

  clientReady() {
    axios.put(`${BASE_RENDER_PATH}/${this.jid}`, {
      async_action: { client_ready: true },
    });
  }

  renderSuccess(secret) {
    axios
      .get(`${BASE_RENDER_PATH}/${this.jid}?secret=${secret}`)
      .then((response) => {
        this.contentWrapperTarget.innerHTML = response.data;
        this.isLoaded();
      })
      .catch(() => this.isErrored());
  }

  renderError(secret) {
    this.isErrored();
  }

  get channelName() {
    return this.data.has("channelName")
      ? this.data.get("channelName")
      : CHANNEL_NAME;
  }

  get jid() {
    return this.data.get("jid");
  }
}
