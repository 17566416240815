import { Controller } from "stimulus";

/**
 * Removes the attached associated parent from the DOM when the `remove` action is called. Any child element can trigger
 * this, such as `ajax:success`.
 */
export default class extends Controller {
  remove() {
    this.element.parentNode.removeChild(this.element);
  }
}
