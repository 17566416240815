import { Controller } from "stimulus";

import $ from "jquery";
import axios from "axios";

if (window.jQuery) $.fn.legacyModal = window.jQuery.fn.modal;

/**
 * Load a fragment from an HTML endpoint and put it into a modal. This controller exists on the Application layout
 * level, so you only need to worry about creating elements that trigger actions.
 *
 *   <a href="/posts/new" data-action="remote-modal#load">New post</a>
 *   <button data-action="remote-modal#load" data-path="/comments/edit">Edit comment</button>
 *
 * You can also add an action that triggers the current modal to close. Here we have an example in which we have a UJS
 * remote link which closes the modal upon success.
 *
 *   <a href="/approvals" data-method="post" data-remote="true" data-action="ajax:success->remote-modal#close">
 *     Approve
 *   </a>
 *
 * NOTE: For now, we're using the "global" bootstrap modal functionality to manage this, until I figure out how I want
 * to import newer libraries. To do this, I grab the `modal` function from the global jQuery object, and set it as the
 * webpack imported jQuery module's `legacyModal`.
 */
export default class extends Controller {
  static targets = ["modal", "modalContent"];

  load(e) {
    e.preventDefault();

    const path =
      e.currentTarget.dataset.path || e.currentTarget.getAttribute("href");
    this.modalContentTarget.innerHTML = "";

    axios
      .get(path)
      .then((response) => {
        this.modalContentTarget.innerHTML = response.data;

        $(this.modalTarget).legacyModal("show");
      })
      .catch((e) => console.error(e));
  }

  close(e) {
    e.preventDefault();
    $(this.modalTarget).legacyModal("hide");
  }
}
