import defaultConfig from "./select2_configs/default";
import employeeSearch from "./select2_configs/employee_search";
import purchaseOrderSearch from "./select2_configs/purchase_order_search";
import revenueOpportunitySearch from "./select2_configs/revenue_opportunity_search";
import saleSearch from "./select2_configs/sale_search";
import supplierSearch from "./select2_configs/supplier_search";
import supplierSelectionSearch from "./select2_configs/supplier_selection_search";
import tagList from "./select2_configs/tag_list";

export default {
  default: defaultConfig,

  employeeSearch: employeeSearch,

  purchaseOrderSearch: purchaseOrderSearch,
  purchaseOrdersSearch: purchaseOrderSearch,

  revenueOpportunitySearch: revenueOpportunitySearch,
  revenueOpportunitiesSearch: revenueOpportunitySearch,

  saleSearch: saleSearch,

  supplierSearch: supplierSearch,
  suppliersSearch: supplierSearch,

  supplierSelectionSearch: supplierSelectionSearch,
  supplierSelectionsSearch: supplierSelectionSearch,

  tagList: tagList,
};
