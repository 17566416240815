import { Controller } from "stimulus";

import Rails from "rails-ujs";

/*
 * Hooks up various mostly-one-liner functions to a form and its controls, such as `reset()` and `focus()`
 */
export default class extends Controller {
  static targets = ["focus"];

  reset(e) {
    e.currentTarget.reset();
  }

  submit() {
    Rails.fire(this.element, "submit");
  }

  // Sets the `focus` target as the focus.
  focus() {
    if (!this.hasFocusTarget) return;

    this.focusTarget.focus();
  }
}
