import { Controller } from "stimulus";

import { camelCase, upperFirst, orderBy, isEqual } from "lodash";
import serializeArray from "../lib/serialize_array";

/*
 * This is probably one of the more dom-manipulation-heavy controllers we'll see here. This takes a list of elements
 * (`item` targets) and reorders how they display on the page. Actions on this controller should eventually call
 * `applyOptions`, which takes an object with two properties: `attribute`, which is the attribute name you want to
 * sort by, and `direction`, which is either `asc` or `desc`. We define the attribute on each `list` target using
 * a `prop_controller` to which that item is attached. See `prop_controller` for more details.
 *
 * The each `item` target must be a direct child of the `list` target. The `list` target must have no other children.
 * This pattern is consistent with
 *
 * The following example uses a form to pass attributes to the controller for sorting, using the `applyFromForm`
 * action. Feel free to add other techniques as you see fit, but anything requiring additional targets, etc. might be
 * worth using a second controller for (thereby making this a composite controller).
 *
 *   <div data-controller="sortable-list">
 *     <form data-action="submit->sortable-list#applyFromForm">
 *       <input type="radio" name="attribute" value="number" checked>
 *       <input type="radio" name="attribute" value="letter">
 *
 *       <input type="radio" name="direction" value="asc" checked>
 *       <input type="radio" name="direction" value="desc">
 *
 *       <input type="submit" value="Sort">
 *     </form>
 *
 *     <ul data-target="sortable-list.list">
 *       <li data-controller="prop" data-target="sortable-list.item" data-prop-number="4" data-prop-letter="D">
 *         Number is 4; letter is D
 *        </li>
 *
 *        <li data-controller="prop" data-target="sortable-list.item" data-prop-number="2" data-prop-letter="J">
 *          Number is 2; letter is J
 *        </li>
 *
 *        <li data-controller="prop" data-target="sortable-list.item" data-prop-number="3" data-prop-letter="G">
 *          Number is 3; letter is G
 *        </li>
 *     </ul>
 *   </div>
 *
 * We can apply custom ordering by defining a list of available values in the root node.
 *
 *   <div data-controller="sortable-list" data-sortable-list-ordering-color="red orange yellow green blue purple">
 *     <!-- ... -->
 *   </div>
 *
 * In the above example, when we sort by the `color` attribute, the item targets will be sorted in the ordering defined
 * by the root node's `data-sortable-list-ordering-color` attribute. Items who don't have that attribute, or whose
 * attribute is not listed, will appear first on the newly sorted list.
 */
export default class extends Controller {
  static targets = ["list", "item"];

  // Intercept a form submission to trigger `applyOptions`.
  applyFromForm(e) {
    e.preventDefault();

    const serializedForm = serializeArray(e.target);
    const options = {};

    options.attribute = serializedForm.find(
      (obj) => obj.name === "attribute"
    ).value;
    options.direction = serializedForm.find(
      (obj) => obj.name === "direction"
    ).value;

    this.applyOptions(options);
  }

  applyOptions(options) {
    const targets = this.itemTargets.map((target) => target);
    const ordering = this.orderingForAttribute(options.attribute);
    const sorted = orderBy(
      targets,
      [this.sortingFunctionForOptions(options, ordering)],
      [options.direction]
    );

    if (isEqual(targets, sorted)) return;

    sorted.forEach((target) => this.listTarget.appendChild(target));
  }

  sortingFunctionForOptions(options, ordering) {
    return (target) => {
      const controller = this.application.getControllerForElementAndIdentifier(
        target,
        "prop"
      );
      const value = controller.value(options.attribute);

      return ordering ? ordering.indexOf(value) : value;
    };
  }

  orderingForAttribute(attribute) {
    const dataAttribute = `ordering${upperFirst(camelCase(attribute))}`;

    return this.data.has(dataAttribute)
      ? this.data.get(dataAttribute).split(" ")
      : null;
  }

  get selection() {
    return this.selectionTarget.value;
  }
}
