// JS
import "admin/application/index.js";
import "admin/controllers";

// CSS
//
// Please avoid adding custom styles in the `application.js` pack. Since this pack is included in both
// `admin/application` and `admin/application2` layout files, it is best to avoid creating styling that may conflict
// with newer layouts. Instead, any custom styling that you with to apply to the legacy `admin/application` layout
// should be added to the Asset Pipeline files.
//
import "admin/application/index.scss";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
