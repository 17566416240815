import { Controller } from "stimulus";

/*
 * Includes functionality for displaying a "show"-style element by default with the option to swap it for an "editable"
 * element. Provides a way to cache previous values to be restored if the edit is cancelled. For an example, see
 * app/views/admin/supplier_selections/_title.html.erb.
 *
 * IMPORTANT: The classes used herein assume the page is using Bootstrap 4+. While Stimulus 2.0 (not yet released)
 * provides a way to denote specific classes for functions like "display"/"hide," this is the generally accepted way to
 * provide this sort of functionality on the current version. We should change this behavior once Stimulus 2.0 is
 * released.
 *
 * IMPORTANT AS WELL: The current accepted pattern for rendering an updated value is to use a remote form which, upon
 * a successful submission, will send a second request to fetch the updated partial. After we have upgraded to Rails 5,
 * we will have better ways of handling this with HTML-over-websockets techniques.
 */
export default class extends Controller {
  static targets = ["base", "editing"];

  connect() {
    this.editingTarget.classList.add("d-none");
  }

  edit(e) {
    e.preventDefault();

    this.cacheValues();

    this.baseTarget.classList.add("d-none");
    this.editingTarget.classList.remove("d-none");
  }

  show() {
    this.editingTarget.classList.add("d-none");
    this.baseTarget.classList.remove("d-none");
  }

  reset(e) {
    e.preventDefault();

    this.restoreCache();
    this.show();
  }

  cacheValues() {
    this.valueCache = {};

    this.cacheableFields.forEach(
      (element) => (this.valueCache[element.name] = element.value)
    );
  }

  restoreCache() {
    if (!this.valueCache) return;

    for (name in this.valueCache) {
      let field = Array.from(this.cacheableFields).find(
        (element) => element.name === name
      );
      if (!field) continue;

      field.value = this.valueCache[name];
    }

    this.valueCache = {};
  }

  get cacheableFields() {
    return this.editingTarget.querySelectorAll("input");
  }
}
