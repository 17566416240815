import { Controller } from "stimulus";

/*
 * `onHover` targets are shown when the mouse enters the element to which this controller is attached.
 */
export default class extends Controller {
  static targets = ["onHover"];

  connect() {
    this.element.onmouseenter = this.mouseEntered.bind(this);
    this.element.onmouseleave = this.mouseLeft.bind(this);

    this.mouseLeft();
  }

  disconnect() {
    this.element.onmouseenter = null;
    this.element.onmouseleave = null;
  }

  mouseEntered() {
    this.onHoverTargets.forEach((target) => target.classList.remove("d-none"));
  }

  mouseLeft() {
    this.onHoverTargets.forEach((target) => target.classList.add("d-none"));
  }
}
