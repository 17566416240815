import { Controller } from "stimulus";

// Basic controls for managing a list of HTML fragments. Provides the ability to remove items from the list from a child
// item, as well as adding items that are rendered via a rails-ujs ajax:success event.
//
//   <div data-controller="fragment-list">
//     <a href="/items" data-remote="true" data-method="post" data-action="ajax:success->fragment-list#appendFromXHR">
//       Add item
//     </a>
//
//     <ul data-target="fragment-list.list">
//       <li data-target="fragment-list.item">
//         These are the contents of the first fragment-list item.
//
//         <a
//           href="/items/1"
//           data-remote="true"
//           data-method="delete"
//           data-action="ajax:success->fragment-list#removeParentItem"
//         >
//           -
//         </a>
//       </li>
//
//       <li data-target="fragment-list.item">
//         These are the contents of the second fragment-list item.
//
//         <a
//           href="/items/2"
//           data-remote="true"
//           data-method="delete"
//           data-action="ajax:success->fragment-list#removeParentItem"
//         >
//           -
//         </a>
//       </li>
//     </ul>
//   </div>
export default class extends Controller {
  static targets = ["list", "item"];

  // Appends the XHR body contents of a rails-ujs ajax:success event to the list.
  appendFromXHR(e) {
    const content = e.detail[0].querySelector("body").innerHTML;
    const dummyDiv = document.createElement("div");

    dummyDiv.innerHTML = content;

    while (dummyDiv.children.length > 0) {
      this.listTarget.append(dummyDiv.children[0]);
    }
  }

  // Propagate this event from within an `item` target. Removes that target from the DOM tree.
  removeParentItem(e) {
    const item = this.itemTargets.find((target) =>
      target.contains(e.currentTarget)
    );

    item.remove();
  }
}
